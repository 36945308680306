body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 20px;
}

ol, ul {
  padding-left: 30px;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.main {
  border: 0px solid #999;
  display: block;
  width: 500px;
}

.select-box {
  border: 0px solid #03a;
}

.select-box-unit {
  border: 0px solid red;
  margin: 20px 0;
}
.name-box {
  display: block;
  border: 0px solid #330;
  background-color: #000;
  color: #ffea36;
  font-size: 38px;
  font-family: serif;
  text-align: center;
  margin: 30px 0;
  padding: 4px;
}

